import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const Gallery = ({ galleryFolder }) => {
  const data = useStaticQuery(graphql`
    query {
      allCloudinaryMedia {
        nodes {
          id
          originalFormat
          originalHeight
          originalWidth
          publicId
          cloudName
          folder
          url
          type
          format
        }
      }
    }
  `);

  const allMedia = data.allCloudinaryMedia.nodes;

  const filteredMedia = allMedia.filter(media =>
    new RegExp(`${galleryFolder}$`).test(media.folder)
  );

  return (
    <Carousel dynamicHeight stopOnHover showArrows showIndicators infiniteLoop useKeyboardArrows>
      {filteredMedia.map(node => (
        <div key={node.id}>
          {(node.format === 'mp4' || node.format === 'mov') ? (
            <video controls>
              <source src={node.url} type={`video/${node.format}`} />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img src={node.url} alt={node.folder} />
          )}
        </div>
      ))}
    </Carousel>
  );
};

export default Gallery;