import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import * as React from 'react'
import { MDXProvider } from '@mdx-js/react'
import Layout from '../../components/layout'
import { reader, date, gridColumn } from '../index.module.css'
import Tree from "../../components/tree"
import CloudinaryMedia from '../../components/CloudinaryMedia'
import Gallery from "../../components/Gallery"

const shortcodes = {
  Tree,
  CloudinaryMedia,
  Gallery,
}
const Project = ({ data }) => {
  return (
    <Layout pageTitle={data.mdx.frontmatter.title}>
      <div className={reader}>
        <p className={date}>{data.mdx.frontmatter.date}</p>
        <MDXProvider components={shortcodes}>
          <MDXRenderer>
            {data.mdx.body}
          </MDXRenderer>
        </MDXProvider>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
        galleryFolder
      }
      body
    }
  }
`
export default Project