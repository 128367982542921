import * as React from 'react'
import {
  apple,
  branch,
  leaf,
} from './tree.module.css';

const Tree = ({ style, nBranches }) => {
  const transProbsCDF = {
    "G": [
      ["G", 0.33],
      ["T", 0.66],
      ["B", 1]
    ],
    "T": [
      ["G", 0.33],
      ["T", 0.66],
      ["B", 1]
    ],
    "B": [
      ["G", 0.33],
      ["T", 0.46],
      ["B", 1]
    ]
  };
  const rootsAndTrunk = ["G", "G"];
  const sample = (n, transProbsCDF, cond, stops) => {
    return cond.concat(Array.from({ length: n - cond.length }).reduce(
      (prevArr, curr, i) => {
        const r = Math.random();
        const prev = prevArr[prevArr.length - 1];
        if (i === n - 1) return 'S'; // stop
        let next = r > transProbsCDF[prev][0][1] ?
          (r > transProbsCDF[prev][1][1] ? transProbsCDF[prev][2][0] : transProbsCDF[prev][1][0]) :
          transProbsCDF[prev][0][0];
        return prevArr.concat(next);
      }, [cond[cond.length - 1]]));
  };  
  // let's sample all counterfactuals, recursively. 
  // That means we sample first the longest sequence
  // then we sample a few counterfactuals (how many? let's say 3)
  // then we sample a few counterfactuals of each of those
  // and we do this until we're satisfied with the number of branches we have
  const sampleCounterfactuals = (root, baseLength) => {
    const newRoot = root.slice(0, baseLength + Math.floor((root.length - baseLength) * Math.random()));
    return sample(
      root.length,
      transProbsCDF,
      newRoot,
      false,
    );
  };
  // let sequences = [sample(nBranches, transProbsCDF, rootsAndTrunk, false)];
  let sequences = [rootsAndTrunk.concat(Array.from('G'.repeat(nBranches)))]
  for (let depth = 0; depth < 9; depth++) {
    sequences = sequences.concat(sequences.map((s) => sampleCounterfactuals(s, rootsAndTrunk.length)));
  };
  // keep track of lowest hanging branch for fruit!
  let lowest = [0,0];
  let rightest = [0,0];
  let leftest = [100,100];
  let highest = [100,100];

  const sequenceToPath = (sequence, origin) => {
    let [x, y] = origin;
    let orientation = Math.PI / 2;
    let segmentLength = 2;
    let pathParts = sequence.reduce((path, segment, i) => {
      const [_, prevX, prevY] = path[path.length - 1].split(' ');
      switch (segment) {
        case 'G':
          return path.concat(`L ${parseFloat(prevX) + segmentLength * Math.cos(orientation)} ${parseFloat(prevY) - segmentLength * Math.sin(orientation)}`);
        case 'B':
          orientation = orientation + Math.PI / 12;
          if(orientation >= Math.PI * 3/2) return path;
          return path.concat(`L ${parseFloat(prevX) + segmentLength * Math.cos(orientation)} ${parseFloat(prevY) - segmentLength * Math.sin(orientation)}`);
        case 'T':
          orientation = orientation - Math.PI / 12;
          if(orientation <= -Math.PI / 2) return path;
          return path.concat(`L ${parseFloat(prevX) + segmentLength * Math.cos(orientation)} ${parseFloat(prevY) - segmentLength * Math.sin(orientation)}`);
        case 'S':
          return path;
        default:
          return path;
      }
    }, [`M ${x} ${y}`]);
    return pathParts.join(' \n');
  }

  return (
    <svg viewBox="25 65 50 35" shapeRendering="optimizeSpeed" style={style} xmlns="http://www.w3.org/2000/svg">
      {sequences.map((_, s) => {
        let pathString = sequenceToPath(sequences[s], [50+s/1000, 100]);
        let terminal = pathString.split(' ').slice(-2).map(parseFloat);
        if(terminal[1] > lowest[1]){
          lowest = terminal;
        }
        if(terminal[1] < highest[1]){
          highest = terminal;
        }
        if(terminal[0] > rightest[0]){
          rightest = terminal;
        }
        if(terminal[0] < leftest[0]){
          leftest = terminal;
        }
        return (
          <>
            <path key={s} pathLength={1} d={pathString} fill="none" strokeWidth={0.01} stroke="var(--textNormal)" strokeLinecap="round" strokeLinejoin="miter" className={branch}/>
          </>
        )
      }
      )}
      {
        <>
          <path key={sequences.length + 1}
          d={`M ${lowest[0]+0.1} ${lowest[1]+0.25} 
              A 0.375 0.5 15 1 1 ${lowest[0]+0.1} ${lowest[1]+1} 
              A 0.375 0.5 -15 1 1 ${lowest[0]+0.1} ${lowest[1]+0.25}`}
          strokeWidth="0.05" pathLength="0.8" stroke="rgba(144, 19, 3, 0.85)" strokeLinecap="round" fill="var(--bg)" className={apple} shapeRendering="geometricPrecision"></path>
        <ellipse cx={lowest[0]+0.15} cy={lowest[1]-0.1} ry="0.1" rx="0.2" key={sequences.length+2} strokeWidth="0.05" transform={`rotate(120,${lowest[0]+0.15}, ${lowest[1]-0.1})`} pathLength="0.8" stroke="rgba(14, 149, 3, 0.85)" strokeLinecap="round" fill="none" className={leaf} shapeRendering="geometricPrecision"></ellipse>
      </>
      }
      <defs>
        <linearGradient id="tree" x1="0%" x2="100%" y1="0%" y2="0%">
          <stop offset="0%" stopColor="rgb(200, 200, 200)" />
          <stop offset="50%" stopColor="rgb(10, 10, 10)" />
          <stop offset="100%" stopColor="rgb(200, 200, 200)" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Tree