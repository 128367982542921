import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby';

const CloudinaryMedia = ({ publicId }) => {
  const data = useStaticQuery(graphql`
    query {
      allCloudinaryMedia {
        nodes {
          id
          originalFormat
          originalHeight
          originalWidth
          publicId
          cloudName
          folder
          url
          type
          format
        }
      }
    }
  `);

  const allMedia = data.allCloudinaryMedia.nodes;

  const filteredMedia = allMedia.filter(media =>
    new RegExp(`${publicId}$`).test(media.publicId)
  );

  if (filteredMedia.length === 0) {
    return null;
  }

  const media = filteredMedia[0];

  return (
    <img src={media.url} alt={media.folder} key={media.id} />
  );
};

export default CloudinaryMedia;